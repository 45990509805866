<template>
  <div class="wdnj">
    <div class="form-list">
      <div class="table-search">
        <h3>农机管理</h3>
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.value" v-for="(item,index) in tabs" :key="index">
            <div class="addBox">
              <div class="addBTn">
                <el-button type="primary" size="mini" @click="addItem">新增</el-button>
              </div>

            </div>
            <table-page :data="tableData" :columns="columns" :currentPage="query.current" :pageSize="query.size"
              :total="total" :loading="loading" :border="true" @sizeChange="handleSizeChange"
              @currentChange="handleCurrentChange">
              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="detailsClick(scope.row)">详情</el-button>
                <el-button type="text" size="small" @click="Edit(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="deleteClick(scope.row)">删除</el-button>
              </template>
            </table-page>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 新增 -->
    <addEdit v-if="isAddEdit" @closeDialog2="closeDialog2" ref="isAddEdit"></addEdit>
  </div>
</template>

<script>
  import TablePage from "@/components/table/table-page";
  import addEdit from './components/addEdit.vue'
  import { selectAgriculturalList, deleteAgriculturalImplement } from '@/api/njgl'

  import { wdnjColumn, tljColumn, njglDetailColumn, njDetailColumn, zdDetailColumn } from '../tableComlumns.js'
  export default {
    name: 'NowIndex',

    data() {
      return {
        activeName: '4',
        columns: tljColumn,
        loading: false,
        isAddEdit: false,
        total: 0,
        query: {
          current: 1,
          size: 10,
        },
        tabs: [
          {
            name: '拖拉机',
            value: '4',
          }, {
            name: '自走机',
            value: '1',

          }, {
            name: '机具',
            value: '2',
          }, {
            name: '终端',
            value: '3',
          }
        ],
        tableData: []
      };
    },

    components: {
      TablePage,
      addEdit
    },
    watch: {
      activeName(newVal) {
        switch (newVal) {
          case '1':
            this.columns = njglDetailColumn;
            break;
          case '2':
            this.columns = njDetailColumn;
            break;
          case '3':
            this.columns = zdDetailColumn
            break;
          case '4':
            this.columns = tljColumn
            // 可以在这里添加处理逻辑，或者直接省略这两个case
            break;
          default:
            this.columns = []; // 或者根据需要设置默认值
            console.warn(`Unexpected activeIndex: ${newVal}`);
            break;
        }
      }
    },

    mounted() {
      this.selectAgriculturalList('4')
    },
    methods: {
      // 关闭弹框
      closeDialog2() {
        this.isAddEdit = false;
        // this.getList()
        this.selectAgriculturalList(this.activeName)
      },
      //点击切换
      handleClick(tab, event) {
        console.log(tab, event);
        console.log(tab.paneName, 'paneName')
        this.activeName = tab.paneName
        this.query.current = 1,
          this.query.size = 10
        this.selectAgriculturalList(tab.paneName)


      },

      //列表数据
      async selectAgriculturalList(type) {
        let params = {
          status: type,
          // current: 1,
          // size: 10,
          ...this.query
        }
        let res = await selectAgriculturalList(params)
        if (res.code == 0) {
          this.tableData = res.data.records
          this.total = Number(res.data.total)
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //点击新增
      addItem() {
        console.log(this.activeName, '查看this.activeName')
        this.isAddEdit = true;
        this.$nextTick(() => {
          this.$refs.isAddEdit.initData2(this.activeName);
        })
      },
      // 点击详情
      detailsClick(obj) {
        this.isAddEdit = true;
        let type = true
        this.$nextTick(() => {
          this.$refs.isAddEdit.EditData(this.activeName, obj, type);
        })
      },
      // 点击编辑
      Edit(obj) {
        console.log(obj, '查看obj是什么')
        this.isAddEdit = true;
        let type = false
        this.$nextTick(() => {
          this.$refs.isAddEdit.EditData(this.activeName, obj, type);
        })

      },
      //删除
      async deleteClick(obj) {
        // console.log(obj.id, '查看obj.id')
        // let res = await deleteAgriculturalImplement({ id: obj.id, type: this.activeName })
        //this.selectAgriculturalList(this.activeName)

        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            console.log(obj.id, '查看obj.id');
            let res = await deleteAgriculturalImplement({ id: obj.id, type: this.activeName });
            this.selectAgriculturalList(this.activeName);
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } catch (error) {
            this.$message({
              type: 'error',
              message: '删除失败，请重试'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //
      //分页总页数切换
      handleSizeChange(size) {
        this.query = {
          current: 1,
          size: size,
        };
        this.selectAgriculturalList(this.activeName);
      },
      handleCurrentChange(page) {
        console.log(page, '查看page是多少')
        this.query.current = page;
        this.selectAgriculturalList(this.activeName);
      },

    },
  };
</script>

<style lang="less" scoped>
  .form-list {
    .table-search {
      h3 {
        margin-left: 20px;
      }

      .addBox {
        width: 100%;
        height: 30px;
        /* background-color: red; */
        display: flex;
        justify-content: flex-end;

        .addBTn {
          width: 70px;
          height: 30rpx;
        }

      }
    }
  }
</style>
